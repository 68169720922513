// React
import React, {Component} from 'react';
import {connect} from 'react-redux'
import {withTranslation} from "react-i18next";
// Styles
import './styles.scss';
// Svg
import {downloadSvg, fileSvg} from "assets";

class File extends Component {

    downloadFile = async (data_url) => {
        window.open(data_url);
    }

    render() {
        const {msg, t} = this.props;
        return (
            <div className={"comment__content__file"}>
                <div className={"comment__content__file_container"}>
                    <div className={"comment__content__file_content"}>
                        <div className={"comment__content__file_svg"}>
                            {fileSvg()}
                            <span>{msg.type}</span>
                        </div>
                        <div className={"comment__content__file_name"}>
                            <span>{t('file_text')}</span><span>.{msg.type}</span>
                        </div>
                    </div>
                    <div className={"comment__content__file_download"}
                         onClick={() => this.downloadFile(msg.src)}>{downloadSvg()}</div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        data: state
    }
}

const connect_elements = connect(
    mapStateToProps
)(File);

export default withTranslation('common')(connect_elements);

