
export class IVRPowers {
    constructor(props, onEvents, webRTC) {
        this.handleData(webRTC);
        this.props = props;
        this.onEvents = onEvents;
        this.myVideoApp = new window.VideoRTC(this.endPoints, this.iceServers, this.debugLevel, this.apiSecret);
    }


    handleData = (type) => {
        switch (type) {
            case '01':
                this.endPoints = [
                    "wss://webrtc.demo.ivrpowers.com:8989",
                    "https://webrtc.demo.ivrpowers.com:8089/webrtc-gateway"
                ];

                this.iceServers = [
                    { urls: "stun:turn.eu.ivrpowers.com:443" },
                    { urls: "turn:turn.eu.ivrpowers.com:443", username: "centribal", credential: "A2a4v1jBj2GpNh2" },
                    { urls: "turn:turn.eu.ivrpowers.com:443?transport=tcp", username: "centribal", credential: "A2a4v1jBj2GpNh2" }
                ];

                this.debugLevel = ["error"];
                this.apiSecret = "not-required-demo-acc";
                this.acc = "centribot-poc";
                this.callerPrefix = "CENTRIBOTPOC";
                break;
            case '02':

                this.endPoints = [
                    "wss://webrtc.demo.ivrpowers.com:8989",
                    "https://webrtc.demo.ivrpowers.com:8089/webrtc-gateway"
                ];

                this.iceServers = [
                    { urls: "stun:turn.eu.ivrpowers.com:443" },
                    { urls: "turn:turn.eu.ivrpowers.com:443", username: "trial", credential: "zN1h_2hBnaH6a3R" },
                    { urls: "turn:turn.eu.ivrpowers.com:443?transport=tcp", username: "trial", credential: "zN1h_2hBnaH6a3R" }
                ];

                this.debugLevel = ["error"];
                this.apiSecret = "not-required-demo-acc";
                this.acc = "not-required-demo-acc";
                this.callerPrefix = "IVRPowersCentribot";
                break;
        }
    }


    async handleMethods(type) {

        switch (type) {

            case 'init':
                this.myVideoApp.connect()
                    .then(usecases => {

                        const domElements = {
                            local: document.getElementById('localvideo'),
                            remote: document.getElementById('remotevideo'),
                            screenRemote: document.getElementById('remotescreen'),
                            screenLocal: document.getElementById('localscreen')
                        };

                        const options = {
                            stream: {
                                audioEnabled: true,
                                videoEnabled: true,
                                aDeviceId: null,
                                vDeviceId: null,
                                resolution: 'vga',
                            },
                            account: this.acc,
                        };

                        usecases.splitClient(this.onEvents, domElements, options)
                            .then(action => {
                                action.call(this.callerPrefix);
                            })
                            .catch(cause => {
                                console.log("Error Attach " + cause);
                            })

                    })
                    .catch(cause => {
                        console.log(cause || "Error connecting with the VideoRTC");
                    })
                break;
            case 'close':
                if (this.myVideoApp) {
                    this.myVideoApp.disconnect();
                }
                break;
        }

    }
}