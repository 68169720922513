'use strict';
import React, {Component} from 'react';
import {ChatApplication, ChatAudio, ChatDocument, ChatImage, ChatVideo} from "./MediaTypes";

class ChatMedia extends Component {

    renderMediaByType = (message) => {
        var type = message.attachment.type || message.attachment?.mime_type?.split('/')[0];
        if (message.attachment.type === "file") {
            type = message.attachment.filetype;
        }

        switch(type) {
            case 'image':
                return <ChatImage attachment={message.attachment}/>;
            case 'application':
                return <ChatApplication attachment={message.attachment}/>;
            case 'audio':
                return <ChatAudio attachment={message.attachment} />
            case 'video':
                return <ChatVideo attachment={message.attachment} />
            case 'document':
                return <ChatDocument attachment={message.attachment} />
        }
    }

    render() {
        return (
            <div className="chat-media-container">
                {this.renderMediaByType(this.props.message)}
            </div>
        );
    }
}

export default ChatMedia;