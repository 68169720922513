import {getParamsUrl} from "application";
import SortedMap from "collections/sorted-map";

export const INITIAL_STATIC_STATE = {
    projectID: getParamsUrl('project_id'),
    chatID: getParamsUrl('chat_id'),
    modeStatic: true,
    modeCookie: getParamsUrl('close'),
    no_animation: getParamsUrl('no_animation'),
    sessionID: "",
    userID: "",
    channel: "",
    webRTC_channel: "",
    accountKEY: "",
    initConversation : "",
    visitor: { display_name: "visitor" },
    agent: { display_name: "agent" },
    config: "",
    warning: false,
    chats: [],
    chats_zendeskChat: SortedMap(),
    last_timestamp: 0
}