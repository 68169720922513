import React, { Component } from 'react';
import { connect } from 'react-redux'
import { IVRPowers } from '../infrastructure/ivr_powers';
import { svgCall, svgCallOut } from 'assets';
import { handleChannelState } from '../infrastructure/handleChannels';

class IVR_Call extends Component {
    constructor(props) {
        super(props);
        this.state = {
            call: false,
            calling: false,
            ivrPowers: new IVRPowers(this.props,
                {
                    onCalling: () => {
                        this.props.updateCall(true);
                        this.setState({ call: true });
                        setTimeout(() => {
                            if ( !this.state.calling ) {
                                this.closeCall();
                            }
                        }, 20000);
                        // Calling a peer
                    },
                    onRegisteredFailed: cause => {
                        // Failed registered
                    },
                    onHangup: (code, cause) => {
                        this.setState({ call: false });
                        this.closeCall();
                        // Hang up call
                    },
                    onAcceptedAudio: (data, stream) => {
                        // Accepted Audio by the remote peer
                    },
                    onReadyLocalAudio: () => {
                        // The local audio  is ready
                    },
                    onReadyLocalStream: (isReady, stream) => {
                        this.props.updateCall(true);
                        // The local video stream is or not ready
                    },
                    onAcceptedVideo: stream => {
                        this.setState({ calling: true, call: true });
                        this.props.updateScreenRemote('video');
                        // Accepted Video by remote peer
                    },
                    onRemoteVideoReady: (isReady, stream) => {
                        this.props.updateCall(true);
                        // The remote video stream is or not ready
                    },
                    onAcceptedData: () => {
                        // Accepted Data by remote peer
                    },
                    onDataReceived: (type) => {
                        // Data received by the datachannel
                    },
                    onStartRecording: () => {
                        // Start recording
                    },
                    onStopRecording: () => {
                        // Stop recording
                    },
                    onScreensharingAccepted: () => {
                        this.props.updateScreenRemote('remote');
                        // ScreenSharing Accepted
                    },
                    onScreensharingClosed: () => {
                        this.props.updateScreenRemote('video');
                        // ScreenSharing Closed
                    },
                    onJoined: () => {
                        // You've joined the SPLIT session
                    },
                    onFileTransferOk: fileId => {
                        // File Transfer OK
                    },
                    onFileTransferKo: fileId => {
                        // File Transfer KO
                    },
                    onNotified: (action, data) => {
                        // Notification received from the remote peer
                    },
                    onVideoClosed: () => {
                        // Remote video is closed
                    },
                    onSipMessage: (sender, content) => {
                        // SIP Message received
                    }
                },
                this.props.webRTC
            ),
        }

    }

    openCall = () => {
        this.props.updateCall();
        setTimeout(() => {
            this.state.ivrPowers.handleMethods('init');
        }, 1000);
    }

    closeCall = async () => {
        this.state.ivrPowers.handleMethods('close');
        const req = new handleChannelState(this.props);
        await req.removeChannel();
        this.props.updateScreenRemote(false);
        this.props.updateCall(false);
    }
    render() {
        const { call } = this.state;
        return (
            <React.Fragment>
                <div className="rtc-buttons">
                    {
                        !call ? (
                            <div onClick={(e) => this.openCall(e)} id="call" className="rubberBand">{svgCall()}</div>
                        ) : (
                            <div onClick={(e) => this.closeCall(e)} id="callOut" className="rubberBand">{svgCallOut()}</div>
                        )
                    }
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        data: state
    }
}

const connect_ivr = connect(
    mapStateToProps
)(IVR_Call);

export default connect_ivr;

