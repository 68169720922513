import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {connect} from 'react-redux';
// Svg
import CloseIcon from '@material-ui/icons/Close';
import {returnSvg} from 'assets';
// API
import {CustomRequest} from 'infrastructure/customRequest';
// Components
import Input from './components/input';
import Articles from './components/articles';
import Article from './components/article';
import {CircularProgress} from "@material-ui/core";
// Styles
import './styles/styles.scss';

import $ from 'jquery';

class ZendeskGuide extends Component {
    constructor(props) {
        super(props);
        this.state = {
            obj: false,
            view: "articles",
            article_selected: false,
            loading: true
        }
    }

    componentDidMount = () => {
        this.getArticles();
    }

    getArticles = async (query, page) => {
        const {config, projectID} = this.props.data;
        this.setState({loading: true, view: "articles"});

        var url = `${process.env.REACT_APP_URL_BACK}projects/${projectID}/channels/${config.channels.zendesk_guide.channel_id}/guide_articles?${page ? page : `query=${query ? query : "%22%22"}&locale=*&per_page=5`} `;

        try {
            var res = await $.ajax(url);
            this.setState({articles: res, loading: false})
        } catch(error) {
            this.setState({articles: {results: []}, view: "articles", loading: false});
        }
    }

    selectArticle = (article) => {
        const {config} = this.props.data;
        if (!config.channels.zendesk_guide.show_article_in_iframe) {
            window.open(article.html_url);
        } else {
            this.setState({article_selected: article, view: "article"});
        }
    }

    render() {
        const {config} = this.props.data;
        const {closeZendeskGuide, inputSvg, t} = this.props;
        const {articles, view, article_selected, loading} = this.state;
        return (
            <React.Fragment>
                <div className={"zendesk_guide slideInLeft"}>
                    {/* CONTAINER */}
                    <div className={"zendesk_guide_container"}>
                        {
                            view === "articles" ?
                                <Articles loading={loading} articles={articles} selectArticle={this.selectArticle} getArticles={this.getArticles}/>
                                :
                                <Article article={article_selected} selectArticle={this.selectArticle}/>
                        }
                    </div>
                    {/* BUTTONS */}
                    <div className={"zendesk_guide_buttons"}>
                        {
                            view === "article" ?
                                <div onClick={() => this.setState({view: "articles"})}
                                     className={"zendesk_guide_buttons_return"}>{returnSvg()}</div> :
                                <div className={"zendesk_guide_buttons_none"}/>
                        }
                        <div onClick={() => closeZendeskGuide()} className={"zendesk_guide_buttons_close"}><CloseIcon/>
                        </div>
                        <div className={"zendesk_guide_buttons_none"}/>
                    </div>
                </div>
                <Input getArticles={this.getArticles} input_text={config.channels.zendesk_guide.input_text}
                       svg={inputSvg}/>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        data: state
    }
}

const connect_component = connect(
    mapStateToProps
)(ZendeskGuide);

export default withTranslation('common')(connect_component);