import $ from 'jquery';

export class Messages {
    constructor(method, url, body) {
        this.base_url = process.env.REACT_APP_URL_BACKMESSAGES;
        this.method = method;
        this.url = url;
        this.body = body;
        this.count = 0;
    }

    async make_request(attachment) {
        try {
            return { response : await $.ajax({
                url: `${this.base_url}${this.url}`,
                type: this.method,
                data: this.body,
                processData: !attachment,
                contentType: attachment ? false : "application/json",
                enctype: attachment ? "multipart/form-data" : false,
            }) , status : 200}
        } catch(error) {
            return { response : error , status : error.status };
        }
    }

    async action(attachment) {
        const res = await this.make_request(attachment);
        this.count++;
        if ( this.count > 3 ) {
            throw 'Too many access requests.'
        } else {
            switch ( res.status ) {
                case ( 200 ) : case ( 201 ) :
                    return res?.response;
                case ( 401 ) :
                    return res?.response;
                case ( 400 ) : case ( 404 ) :
                    throw res?.response?.responseJSON?.message;
                case ( 500 ) :
                    throw 'Error 500 Internal Server';
                default :
                    throw res?.response;
            }
        }
    }
}