import React, { Component } from 'react';

class Avatar extends Component {
  getVisitorSvg(icon) {
    if (icon) {
      return <img src={icon} alt={"Visitor"}/>
    } return (
        <img src={process.env.PUBLIC_URL + '/assets/user.svg'} alt={"Visitor"}/>
    );
  }

  getAgentSvg(icon) {
    if (icon) {
      return <img src={icon} alt={"Agent"}/>
    } return (
        <img src={process.env.PUBLIC_URL + '/assets/agent.svg'} alt={"Agent"}/>
    );
  }

  renderAvatar() {
    const { config, nick } = this.props;
    let child;
    if (nick === 'agent' ) {
        child = this.getAgentSvg(config.agent_icon);
    } else if ( nick === 'visitor' ) {
        child = this.getVisitorSvg(config.user_icon);
    }

    return (
      <div className="avatar" title={nick}>
        {child}
      </div>
    );
  }

  render() {
    return this.renderAvatar();
  }
}

export default Avatar;