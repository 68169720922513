import React, {Component} from 'react';
import {connect} from 'react-redux'
import './styles.scss';
// SVG
import {svgDownload} from 'assets';

class ReplyButton extends Component {
    constructor(props) {
        super(props);
    }

    renderTypeElement = (type, src) => {
        switch (type) {
            case 'text':
                return <strong>{src}</strong>
            case 'image':
                return <img src={src} alt={"reply image"}/>;
            case 'video':
                return <React.Fragment>
                    <video controls>
                        <source src={src} />
                        El vídeo no funciona o la extensión no es válida
                    </video>
                </React.Fragment>
            case 'document':
                var split = src.split('.'),
                    ext = split[split.length - 1];
                return <div onClick={() => window.open(src)} className={"document_type"}>
                    <span>document.{ext}</span>
                    <span>{svgDownload()}</span>
                </div>
            default:
                return '';
        }
    }

    handleResponse = (e, value) => {
        const {incomingResponses} = this.props;

        this.props.dispatch({type: 'synthetic', detail: {type: 'visitor_send_msg', msg: value}});
        e.target.disabled = true;
        incomingResponses(value);
    }

    render() {
        const {msg} = this.props;
        return (
            <div className={"artifact_reply_button"}>
                <div className={"artifact_reply_button__header"}>
                    {this.renderTypeElement(msg.header_type, msg.header_value)}
                </div>
                <div className={"artifact_reply_button__body"}>
                    <span dangerouslySetInnerHTML={{__html: msg.body}} />
                    <span dangerouslySetInnerHTML={{__html: msg.footer}} />
                </div>
                <div className={"artifact_reply_button__buttons"}>
                    {
                        msg.values?.map((value, i) => {
                            return (
                                <button className={"btn-primary button-reply"} disabled={msg.disabled} onClick={(e) => this.handleResponse(e, value)}
                                        key={i}>{value}</button>
                            )
                        })
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        data: state
    }
}

const connect_elements = connect(
    mapStateToProps
)(ReplyButton);

export default connect_elements;
