// ACTION
import { updateSession } from '../action';
import { integrationCall } from '../infrastructure/integrationCall';

export class handleChannelState {
    constructor(props) {
        this.props = props;
    }

    async setZendeskChat(accountKEY) {
        const { sessionID, channel, initConversation, webRTC_channel } = this.props.data;
        const body = {
            session: sessionID,
            channel: channel,
            account_key: accountKEY,
            init: initConversation,
            webRTC_channel: webRTC_channel
        }
        this.props.dispatch(updateSession(JSON.stringify(body)));
    }

    async setChannel(channel, webRTC) {
        const { sessionID, accountKEY, initConversation, webRTC_channel } = this.props.data;
        const body = {
            session: sessionID,
            channel: channel,
            account_key: accountKEY,
            init: initConversation,
            webRTC_channel: webRTC ? webRTC : webRTC_channel
        }

        this.props.dispatch(updateSession(JSON.stringify(body)));
    }

    async removeChannel() {
        const { sessionID, accountKEY } = this.props.data;
        const body = {
            session: sessionID,
            channel: false,
            account_key: accountKEY,
            init: false,
            webRTC_channel: false
        }
        this.props.dispatch(updateSession(JSON.stringify(body)));

    }

    async init() {
        const { sessionID, accountKEY, channel, chats, webRTC_channel, initConversation} = this.props.data;
        const body = {
            session: sessionID,
            channel: channel,
            account_key: accountKEY,
            init: true,
            webRTC_channel : webRTC_channel
        }
        this.props.dispatch(updateSession(JSON.stringify(body)));

        if (channel === 'zendesk_chat' && !initConversation) {
            setTimeout(() => {
                chats.toArray().forEach(message => {
                    const request = new integrationCall(this.props, this.props.data.channel, 'sendMessage', `|| ${message.nick} : ${message.type === 'chat.msg' ? message.msg : 'Attachments files & elements' } ||`);
                    request.call();
                });

            }, 1000);
        }

    }
}