// React
import React, {Component} from 'react';
import {connect} from 'react-redux'
// Styles
import './styles.scss';
// Assets
import {arrowNextSvg, arrowPrevSvg} from "assets";

function Item(props) {
    return (
        <div className={`lightbox_slider_item lightbox_slider_image`} onClick={() => window.open(props.item.link)}>
            <div className={"image"}>
                <div style={{backgroundImage: `url("${props.item.src}")`}}/>
            </div>
        </div>
    )
}
class CarouselMobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uuid: this.getUuid(),
            scroll_left: 0
        }
    }

    getUuid = () => {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }

    handleResponse = (value) => {
        const {incomingResponses} = this.props;
        if (value) {
            this.props.dispatch({type: 'synthetic', detail: {type: 'visitor_send_msg', msg: value}});

            incomingResponses(value);
        }
    }

    handlePagination = (e, type) => {
        var element = document.getElementById(`lightbox_${this.state.uuid}`),
            value = element?.scrollLeft,
            leftMax = element?.scrollWidth - element?.offsetWidth;

        switch (type) {
            case 'next':
                if (element?.scrollLeft === 0) {
                    value += 155;
                } else {
                    value += 150;
                }
                break;
            case 'prev':
                if (element?.scrollLeft >= leftMax) {
                    value -= 155;
                } else {
                    value -= 150;
                }
                break;
        }

        document.getElementById(`lightbox_${this.state.uuid}`).scrollTo({
            left: value,
            behavior: 'smooth',
        });
        this.setState({scroll_left: value});
    }

    handleDisplay = (type) => {
        var element = document.getElementById(`lightbox_${this.state.uuid}`),
            leftMax = element?.scrollWidth - element?.offsetWidth;
        const {scroll_left} = this.state;

        switch (type) {
            case 'prev':
                if (scroll_left <= 5) {
                    return 'hidden_action';
                } break;
            case 'next':
                if (scroll_left >= leftMax ?? 0) {
                    return 'hidden_action';
                } break;
        }

        return "";
    }

    render() {
        const {items} = this.props;
        const {uuid} = this.state;
        return (
            items?.length > 0 &&
            <div className={`lightbox_slider`}>
                <div>
                    <div className={`lightbox_slider_action prev ${this.handleDisplay('prev')}`}
                         onClick={(e) => this.handlePagination(e, "prev")}>{arrowPrevSvg()}</div>
                    <div id={`lightbox_${uuid}`} className={`lightbox_slider_content`}>
                        {
                            items.map((item, i) => {
                                return <Item item={item} handleResponse={this.handleResponse}
                                             incomingResponses={this.props.incomingResponses} key={i}/>
                            })
                        }
                    </div>
                    <div className={`lightbox_slider_action next ${this.handleDisplay('next')}`}
                         onClick={(e) => this.handlePagination(e, "next")}>{arrowNextSvg()}</div>
                </div>
            </div>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        data: state
    }
}

const action_carousel = connect(
    mapStateToProps
)(CarouselMobile);

export default action_carousel;
