import React, {Component} from 'react';
import {connect} from 'react-redux'
import Mobile from "./mobile";
import Default from "./default";

class Carousel extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {msg} = this.props;
        return (
            ["mobile"].includes(msg.type) ?
                <Mobile {...this.props} /> :
                <Default {...this.props} />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        data: state
    }
}

const action_carousel = connect(
    mapStateToProps
)(Carousel);

export default action_carousel;
