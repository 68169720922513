// REDUX STORED
import {createStore} from 'redux';
import SortedMap from 'collections/sorted-map';
// APPLICATION
import {setCookieSession, existSession, getParamsUrl} from '../application';
// INITIAL STATE
import {INITIAL_STATIC_STATE} from './initial_state/static';
import {INITIAL_NORMAL_STATE} from './initial_state/normal';
import moment from 'moment';

existSession();

const modeCookie = getParamsUrl('close');

const INITIAL_STATE = modeCookie ? INITIAL_STATIC_STATE : INITIAL_NORMAL_STATE;

function update(state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'chat':
            let new_state = {...state};
            switch (action.detail.type) {
                case 'chat.elements':
                case 'chat.elements.carousel':
                case 'chat.file':
                case 'chat.msg':
                case 'chat.typing':
                case 'chat.request.rating':
                case 'chat.memberleave':
                case 'chat.rating.message':
                    if (state.channel) {
                        new_state.chats_zendeskChat = SortedMap();
                    } else {
                        new_state.chats_zendeskChat = state.chats_zendeskChat.concat({
                            ...action.detail,
                            member_type: action.detail.display_name
                        });
                    }

                    new_state.chats = state.chats.concat({
                        ...action.detail,
                        member_type: action.detail.display_name
                    });
                    return new_state;
                default:
                    return state;
            }
        default:
            return state;
    }
}

const storeReducer = (state = INITIAL_STATE, action) => {
    let result, new_action = {};
    const new_timestamp = + new Date();

    switch (action.type) {
        case 'synthetic':
            switch (action.detail.type) {
                case 'visitor_send_msg':
                    new_action = {
                        type: 'chat',
                        detail: {
                            type: 'chat.msg',
                            display_name: state.visitor.display_name,
                            nick: 'visitor',
                            timestamp: action.detail.created_at ? action.detail.created_at : new_timestamp,
                            msg: action.detail.msg,
                            desk_name: action.detail.desk_name
                        }
                    };
                    break;
                case 'agent_send_msg':
                    new_action = {
                        type: 'chat',
                        detail: {
                            type: 'chat.msg',
                            display_name: state.agent.display_name,
                            nick: 'agent',
                            timestamp: action.detail.created_at ? action.detail.created_at : new_timestamp,
                            msg: action.detail.msg,
                        }
                    };
                    break;
                default:
                    new_action = action;
            }
            result = update(state, new_action);
            break;
        case 'typing':
            new_action = {
                type: 'chat',
                detail: {
                    type: 'chat.typing',
                    display_name: "agent",
                    nick: 'agent',
                    timestamp: new_timestamp
                }
            };
            result = update(state, new_action);
            break;
        case 'components':
            new_action = {
                type: 'chat',
                detail: {
                    type: 'chat.elements',
                    display_name: state.agent.display_name,
                    nick: 'agent',
                    timestamp: new_timestamp,
                    msg: action.detail.msg,
                }
            };
            result = update(state, new_action);
            break;
        case 'carousel':
            new_action = {
                type: 'chat',
                detail: {
                    type: 'chat.elements.carousel',
                    display_name: state.agent.display_name,
                    nick: 'agent',
                    timestamp: new_timestamp,
                    msg: action.detail.msg,
                }
            };
            result = update(state, new_action);
            break;
        case 'file_from_chat':
        case 'system_message':
            new_action = {
                type: 'chat',
                detail: {
                    type: action.detail.type,
                    display_name: state.agent.display_name,
                    nick: action.detail.nick.split(':')[0],
                    timestamp: new_timestamp,
                    attachment: action.detail.attachment,
                    msg: action.detail.msg,
                }
            };
            result = update(state, new_action);
            break;
        case 'file':
            new_action = {
                type: 'chat',
                detail: {
                    type: 'chat.file',
                    display_name: 'agent',
                    nick: 'agent',
                    timestamp: new_timestamp,
                    attachment: action.detail.attachment,
                    msg: action.detail.msg
                }
            };
            result = update(state, new_action);
            break;
        default:
            switch (action.type) {
                case 'UPDATE_KEY': {
                    return Object.assign({}, state, {
                        projectID: action.data.split('.')[0],
                        chatID: action.data.split('.')[1]
                    })
                }
                case 'UPDATE_SESSION': {
                    if (!modeCookie) {
                        setCookieSession(action.data, state.chatID, 'bc__s');
                    }
                    var d = JSON.parse(action.data);
                    return Object.assign({}, state, {
                        sessionID: d.session,
                        channel: d.channel,
                        accountKEY: d.account_key,
                        initConversation: d.init,
                        webRTC_channel: d.webRTC_channel
                    })
                }
                case 'UPDATE_USER': {
                    if (!modeCookie) {
                        setCookieSession(action.data, state.chatID, 'bc__u');
                    }
                    var d = JSON.parse(action.data);
                    return Object.assign({}, state, {
                        userID: d.user_id
                    })
                }
                case 'UPDATE_CONFIG': {
                    if (!modeCookie) {
                        setCookieSession(btoa(unescape(encodeURIComponent(JSON.stringify(action.data)))), state.chatID, 'bc__cf');
                    }

                    return Object.assign({}, state, {
                        config: action.data
                    })
                }
                case 'UPDATE_CHATS': {
                    return Object.assign({}, state, {
                        chats: action.data
                    })
                }
                case 'UPDATE_WARNING':
                    return Object.assign({}, state, {
                        warning: action.data
                    })
                default: {
                    return state;
                }
            }
    }

    return result;
}


let reducer = createStore(storeReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

export default reducer;
