import React from 'react';
import ReactDOM from "react-dom";
import {Provider} from 'react-redux';
import Chat from './components/Chat';
import storeReducer from './reducers';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import moment from "moment";
import 'moment/locale/es';
import 'moment/locale/it';
import 'moment/locale/fr';
import 'moment/locale/de';
import 'moment/locale/pt';
import {Detector} from "react-detect-offline";
// ES
import common_es from "./translations/es/common.json";
// IT
import common_it from "./translations/it/common.json";
// PT
import common_pt from "./translations/pt/common.json";
// FR
import common_fr from "./translations/fr/common.json";
// DE
import common_de from "./translations/de/common.json";
// EN
import common_en from "./translations/en/common.json";

import './styles/index.scss';

var userLang = navigator.language || navigator.userLanguage;
var lang = userLang.split('-')[0];
moment.locale(lang);
i18next.init({
    interpolation: {escapeValue: false},
    resources: {
        en: {common: common_en},
        es: {common: common_es},
        it: {common: common_it},
        pt: {common: common_pt},
        de: {common: common_de},
        fr: {common: common_fr}
    },
    lng: lang,
    fallbackLng: "en"
});

const polling = {
    url: "https://ipv4.icanhazip.com",
    enabled: true,
    interval: 60000,
    timeout: 5000
}; 

ReactDOM.render(
    <I18nextProvider i18n={i18next}>
        <Provider store={storeReducer}>
            <Detector
                polling={polling}
                render={({online}) => (
                    <Chat isOnline={online}/>
                )}
            />
        </Provider>
    </I18nextProvider>,
    document.getElementById("root"));
