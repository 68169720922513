import $ from 'jquery';

export class CustomRequest {
    constructor(method, url, body) {
        this.base_url = process.env.REACT_APP_URL_BACK;
        this.method = method;
        this.url = url;
        this.body = body;
        this.count = 0;
    }

    async make_request() {
        try {
            return { response : await $.ajax({
                url: `${this.base_url}${this.url}`,
                type: this.method,
                data: this.body,
                contentType: "application/json",
            }) , status : 200}
        } catch(error) {
            return { response : error , status : error.status };
        }
    }

    async action() {
        const first = await this.make_request(process);
        this.count++;
        if ( this.count > 3 ) {
            throw 'Too many access requests.'
        } else {
            switch ( first.status ) {
                case ( 200 ) : case ( 201 ) :
                    return first.response;
                case ( 401 ) :
                    return first.response;
                case ( 400 ) : case ( 404 ) :
                    throw first.response.responseJSON.message;
                case ( 500 ) :
                    throw 'Error 500 Internal Server';
                default :
                    throw first.response;
            }
        }
    }
}