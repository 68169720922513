'use strict';
import React, {Component} from 'react';
export class ChatAudio extends Component {
    render() {
    const {attachment} = this.props;
        return (
            <audio controls="controls">
                <source src={attachment.url}/>
            </audio>
        );
    }
}