// React
import React, {Component} from 'react';
// Svg
import {fileSvg, downloadSvg} from "assets";
// Styles
import '../styles.scss';

export class ChatDocument extends Component {

    downloadFile = (data_url) => {
        window.open(data_url);
    }

    render() {
        const {attachment} = this.props;
        const file_split = attachment.filename.split('.'),
            file_type = file_split[file_split.length - 1],
            file_name = attachment.filename.split('.')[0];
        return (
            <div className={"comment__content__file"}>
                <div className={"comment__content__file_container"}>
                    <div className={"comment__content__file_content"}>
                        <div className={"comment__content__file_svg"}>
                            {fileSvg()}
                            <span>{file_type}</span>
                        </div>
                        <div className={"comment__content__file_name"}>
                            <span>{file_name}</span><span>.{file_type}</span>
                        </div>
                    </div>
                    <div className={"comment__content__file_download"} onClick={() => this.downloadFile(attachment.url)}>{downloadSvg()}</div>
                </div>
            </div>
        );
    }
}