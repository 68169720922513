import React, {Component} from 'react';
import {withTranslation} from "react-i18next";

class ChatButton extends Component {

    getAgentImg = () => {
        const {config} = this.props;
        if (config && config.agent_icon) {
            return config.agent_icon
        } else return `assets/centribal.png`;
    }

    getChatImg = () => {
        const {config} = this.props;
        if (config && config.chat_icon) {
            return config.chat_icon
        } else return `assets/centribal.png`;
    }

    render() {
        const {
            distribution,
            addClass,
            onClick,
            onHoverUp,
            onHoverDown,
            position,
            fullWidthLogo,
            config,
            t
        } = this.props;
        return (
            <React.Fragment>
                {
                    position === 'left' ? (
                        <div className={`chat-container left ${distribution} ${addClass}`}
                             onClick={onClick}
                             onMouseEnter={onHoverUp}
                             onMouseLeave={onHoverDown}
                        >
                            <div className={`chat-button ${fullWidthLogo ? 'full_width' : ''}`}>
                                <img src={this.getChatImg()}/>
                            </div>
                            <div className="chat-agent">
                                <span>{config?.text_expanded ?? t('chat_button.description')}</span>
                            </div>
                        </div>
                    ) : (
                        <div className={`chat-container right ${distribution} ${addClass}`}
                             onClick={onClick}
                             onMouseEnter={onHoverUp}
                             onMouseLeave={onHoverDown}
                        >
                            <div className="chat-agent">
                                <span>{config?.text_expanded ?? t('chat_button.description')}</span>
                            </div>
                            <div className={`chat-button ${fullWidthLogo ? 'full_width' : ''}`}>
                                <img src={this.getChatImg()}/>
                            </div>
                        </div>

                    )
                }
            </React.Fragment>
        );
    }
}

export default withTranslation('common')(ChatButton);