'use strict';
import React, {Component} from 'react';
export class ChatVideo extends Component {
    render() {
    const {attachment} = this.props;
        return (
            <video controls>
                <source src={attachment.url}/>
            </video>
        );
    }
}