import React, {Component} from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
// ICONS
import {Card, CardContent} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MaximizeIcon from '@material-ui/icons/WebAsset';
import MinimizeIcon from '@material-ui/icons/FilterNone';
import {articleSvg} from "assets";
// COMPONENTS
import IVR_Call from './IVR_Call';

class StatusContainer extends Component {

    getAgentImg = () => {
        const {config} = this.props;
        if (config && config.header_icon) {
            return config.header_icon
        } else return `assets/centribal.png`;
    }

    setClass = (type, status) => {
        switch (type) {
            case 'local':
                if (!status) {
                    return 'hidden';
                }
                return '';
            case 'video':
                if (status && status === 'video') {
                    return '';
                }
                return 'hidden';
            case 'remote':
                if (status && status === 'remote') {
                    return '';
                }
                return 'hidden';
        }
    }

    showCloseButton = () => {
        const {minimizeWidget, modeCookie, modeStatic, controlIframe} = this.props;

        if ((modeStatic && controlIframe) || !modeStatic || modeCookie) {
            return (
                <div onClick={minimizeWidget}>
                    <CloseIcon/>
                </div>
            )
        } else return '';
    }

    render() {
        const {
            minimizeChat,
            maximizeChat,
            minimizeWidget,
            config,
            channel,
            position,
            updateCall,
            call,
            screenRemote,
            updateScreenRemote,
            webRTC,
            mobile,
            modeCookie,
            modeStatic,
            handleZendeskGuide,
            zendeskGuide
        } = this.props;

        const existZendeskGuide = config?.channels?.zendesk_guide?.show_in_chat;
        return (
            <div className="header">
                <div className="header-container">
                    <div className="header-content">
                        <img src={this.getAgentImg()} alt={"Agent"}/>
                        <div>
                            {
                                zendeskGuide && existZendeskGuide ?
                                    <span>{config?.channels?.zendesk_guide?.title}</span> :
                                    <span
                                        dangerouslySetInnerHTML={{__html: config?.header_title.replaceAll(/\n/g, '<br>')}}/>

                            }
                            <span/>
                        </div>
                    </div>
                    <div className="btn-container">
                        {(channel === 'ivr_powers' && !zendeskGuide) &&
                        <IVR_Call screenRemote={screenRemote} updateScreenRemote={updateScreenRemote}
                                  updateCall={updateCall} webRTC={webRTC}/>}
                        {
                            !zendeskGuide && existZendeskGuide && channel !== 'ivr_powers' &&
                            <div onClick={() => handleZendeskGuide()}>
                                {articleSvg()}
                            </div>
                        }
                        {!mobile && !modeStatic &&
                        <React.Fragment>
                            {position !== 'maximize' ? (
                                <div onClick={maximizeChat}>
                                    <MaximizeIcon/>
                                </div>
                            ) : (
                                <div onClick={minimizeChat}>
                                    <MinimizeIcon/>
                                </div>
                            )}
                        </React.Fragment>
                        }
                        {this.showCloseButton()}
                    </div>
                </div>
                {call &&
                <Card elevation={0} className={'rubberBand call-rtc'}>
                    <CardContent>
                        <div className="call-videos">
                            <div className={this.setClass('local', screenRemote)} id="localvideo"></div>
                            <div className={this.setClass('video', screenRemote)} id="remotevideo"></div>
                            <div className={this.setClass('remote', screenRemote)} id="remotescreen"></div>
                        </div>
                    </CardContent>
                </Card>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        data: state
    }
}

const container = connect(
    mapStateToProps
)(StatusContainer);

export default withTranslation('common')(container);