import React, {Component} from 'react';
import {connect} from 'react-redux'
// Translations
import {withTranslation} from 'react-i18next';

class ChatSystemMessage extends Component {
    constructor(props) {
        super(props);
    }

    renderMessage = (message) => {
        if (message.type === 'chat.rating.messag') {
                return (
                    <span className={"rating-message"}>
                        {this.props.t('system.rating_message')}
                    </span>
                )
        }
    }

    render() {
        return (
            <div className={`chat-msg-container`}>
                {this.renderMessage(this.props.message)}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        data: state
    }
}

const connect_chat = connect(
    mapStateToProps
)(ChatSystemMessage);

export default withTranslation('common')(connect_chat);
