import React, {Component} from 'react';
import {connect} from 'react-redux'
import './styles.scss';
// SVG
import {OpenInNew} from "@material-ui/icons";

class Postback extends Component {
    constructor(props) {
        super(props);
    }

    handleResponse = (e, value) => {
        const {incomingResponses} = this.props;

        this.props.dispatch({type: 'synthetic', detail: {type: 'visitor_send_msg', msg: value}});
        e.target.disabled = true;
        incomingResponses(value);
    }

    render() {
        const {msg} = this.props;
        return (
            <div className={"artifact_postback"}>
                <div className={"artifact_postback__header"}>
                    <p>{msg.text}</p>
                </div>
                <div className={"artifact_postback__buttons"}>
                    {
                        msg.buttons?.map((button, i) => {
                            return (
                                <React.Fragment key={i}>
                                    {
                                        button.type === "postback" &&
                                        <button className={"btn-primary button-postback"} disabled={msg.disabled}
                                                onClick={(e) => this.handleResponse(e, button.text)}
                                                key={i}>{button.text}</button>
                                    }
                                    {
                                        button.type === "link" &&
                                        <button onClick={() => window.open(button.url)} disabled={msg.disabled}
                                                className="btn-primary elements_button button-postback button-link">{button.text} <span
                                            className={"button-new"}><OpenInNew/></span></button>
                                    }

                                </React.Fragment>
                            )
                        })
                    }
                        </div>
                        </div>
                        );
                    }
}

const mapStateToProps = (state) => {
    return {
        data: state
    }
}

const connect_elements = connect(
    mapStateToProps
)(Postback);

export default connect_elements;
