import {getCookieInfo, getParamsUrl} from "application";
import SortedMap from "collections/sorted-map";

const chatID = getParamsUrl('chat_id');

export const INITIAL_NORMAL_STATE = {
    projectID: getParamsUrl('project_id'),
    chatID: chatID,
    modeStatic: getParamsUrl('mode'),
    modeCookie: false,
    no_animation: getParamsUrl('no_animation'),
    sessionID: getCookieInfo('session', chatID, 'bc__s'),
    userID: getCookieInfo('user_id', chatID, 'bc__u'),
    channel: getCookieInfo('channel', chatID, 'bc__s'),
    webRTC_channel: getCookieInfo('webRTC', chatID, 'bc__s'),
    accountKEY: getCookieInfo('account_key', chatID, 'bc__s'),
    initConversation: getCookieInfo('init', chatID, 'bc__s'),
    visitor: {display_name: "visitor"},
    agent: {display_name: "agent"},
    config: getCookieInfo('', chatID, 'bc__cf'),
    warning: false,
    chats: [],
    chats_zendeskChat: SortedMap(),
    last_timestamp: 0
}