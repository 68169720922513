export class CustomizeCSS {

    constructor(props, methods, config) {
        this.props = props;
        this.methods = methods;
        this.config = config;
    }

    initCustomize = () => {
        const root = document.documentElement;
        const conf = this.config.customization;

        Object.entries(conf).map((element) =>  {
            var variableName = element[0], value = element[1];
            if (variableName.includes('show')) {
                root.style.setProperty(`--${variableName}`, value ? 'initial' : 'none');
            } else if (variableName === 'open_shape') {
                if (typeof value == "boolean") {
                    root.style.setProperty(`--${variableName}`, value ? '50px' : '0px');
                } else {
                    root.style.setProperty(`--${variableName}`, value);
                }
            } else {
                root.style.setProperty(`--${variableName}`, value);
            }
        })
    }


}