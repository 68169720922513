export const resetState = () => {
    return {
        type: 'RESET_STATE',
    }
}

export const updateKey = data => {
    return {
        type: 'UPDATE_KEY',
        data
    }
}

export const updateSession = data => {
    return {
        type: 'UPDATE_SESSION',
        data
    }
}

export const updateUser = data => {
    return {
        type: 'UPDATE_USER',
        data
    }
}

export const updateConfig = data => {
    return {
        type: 'UPDATE_CONFIG',
        data
    }
}

export const showNotify = data => {
    return {
        type: 'SHOW_NOTIFY',
        data
    }
}

export const updateChats = data => {
    return {
        type: 'UPDATE_CHATS',
        data
    }
}

export const updateWarning = data => {
    return {
        type: 'UPDATE_WARNING',
        data
    }
}