'use strict';
import React, {Component} from 'react';
export class ChatImage extends Component {
    render() {
        const {attachment} = this.props;
        return (
            <a href={attachment.url} target="_blank" className="chat-img-container">
                <div
                    className="chat-img"
                    style={{
                        backgroundImage: `url("${attachment.url}")`
                    }}
                />
            </a>
        );
    }
}