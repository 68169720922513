import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {connect} from 'react-redux';
import ChatMessage from './ChatMessage';
import ChatElements from './ChatElements';
import ChatSystemMessage from './ChatSystemMessage';
import {poweredVisibility} from '../application';
import $ from 'jquery';

class MessageList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: "https://demo.centribal.com/crea-tu-chatbot-en-plataforma-centribal"
        };
    }

    componentDidMount() {
        const {projectID} = this.props.data;
        if (projectID === '38e26ca99eed402087a9b7919b53b40d') {
            this.setState({url: 'https://bit.ly/3fFYmSm'});
        } else if (projectID === '66e5cd3f48054a68a71647214b4cae90') {
            this.setState({url: 'https://bit.ly/2SNkO2R'});
        }
    }

    componentDidUpdate(nextProps) {
        // Scroll to last message
        var c = $('#message-container');
        c?.scrollTop(c?.prop("scrollHeight"));
    }

    renderByType = (msg, addClass) => {
        switch (msg.type) {
            case 'chat.msg':
                if (msg.msg !== 'only_attachment' && msg.msg) {
                    return (
                        <ChatMessage
                            key={msg.type + msg.timestamp}
                            message={msg}
                            addClass={addClass}
                            config={this.props.config}
                        />
                    );
                }
                break;
            case 'chat.typing':
                return (
                    <div key={msg.type + msg.timestamp} className={`chat-msg-container agent sibling`}>
                        <div className={"centribal_typing"}>
                            <div className={"centribal_typing_container"} />
                        </div>
                    </div>
                )
            case 'chat.file':
                return (
                    <ChatMessage
                        key={msg.type + msg.timestamp}
                        message={msg}
                        addClass={addClass}
                        config={this.props.config}
                    />
                );
            case 'chat.elements':
            case 'chat.elements.carousel':
                return (
                    <ChatElements
                        key={msg.type + msg.timestamp}
                        incomingResponses={this.props.incomingResponses}
                        message={msg}
                        addClass={addClass}
                        config={this.props.config}
                    />
                );
            case 'chat.rating.message':
                return (
                    <ChatSystemMessage
                        key={msg.type + msg.timestamp}
                        message={msg}
                    />
                );
            default:
                return false
        }
    }

    renderAll = (messages) => {
        const {config} = this.props;
        let prev = null;

        return messages.map((message, i) => {
            if (message.type === "chat.typing" && messages.length !== (i+1)) {
                return ""
            }

            let addClass = '',
                currentNick = message.nick,
                prevNick = prev && prev.nick;

            if (currentNick && currentNick === prevNick)
                addClass = 'sibling';


            // Disable buttons from artifacts
            message.disabled = !(message.display_name === "agent" && this.detectVisitor(messages, i));

            if (!config.customization.buttons_disable) {
                message.disabled = false;
            }

            prev = message;

            return this.renderByType(message, addClass);
        });
    }

    detectVisitor = (messages, i) => {
        var res = true;
        for (var x=i; x < messages.length; x++) {
            if (messages[x].display_name === "visitor") {
                res = false;
            }
        }

        return res;
    }

    poweredHardcoded = () => {
        const {config} = this.props;

        return this.state.url !== 'https://demo.centribal.com/crea-tu-chatbot-en-plataforma-centribal' || poweredVisibility(config.plan);
    }

    render() {
        const {messages} = this.props;
        const {url} = this.state;
        return (
            <div className={"message-list-content"}>
                <div id="message-container"
                     className={`message-list-container ${this.poweredHardcoded() ? 'pd_bottom' : ''}`}>
                    {this.renderAll(messages)}
                    {
                        this.poweredHardcoded() &&
                        <a href={url} target="_blank">
                            <img className="powered" src={process.env.PUBLIC_URL + '/assets/powered.png'}
                                 alt={"powered"}/>
                        </a>
                    }
                </div>
            </div>
        );
    }
}

MessageList.displayName = 'MessageList';

const mapStateToProps = (state) => {
    return {
        data: state
    }
}

const connect_MessageList = connect(
    mapStateToProps
)(MessageList);

export default withTranslation('common')(connect_MessageList);