import React, {Component} from 'react';
import {connect} from 'react-redux'
// Components
import Detached from "./detached";
import Default from "./default";
// Styles
import './styles.scss';

class Reply extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {msg, incomingResponses} = this.props;
        return (
            ["icons", "in_line_auto"].includes(msg.type) ?
                <Detached msg={msg} incomingResponses={incomingResponses}/> :
                <Default msg={msg} incomingResponses={incomingResponses}/>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        data: state
    }
}

const connect_elements = connect(
    mapStateToProps
)(Reply);

export default connect_elements;
