import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {connect} from 'react-redux';
import {CircularProgress} from '@material-ui/core';

class MessageInfo extends Component {
    render() {
        const {loadingMessages, t} = this.props;
        return (
            (loadingMessages) &&
            <div className={"message-list-info"}>
                <div>
                    <span>{t('loading_messages')}</span>
                    <CircularProgress size={18}/>
                </div>
            </div>
        );
    }
}

MessageInfo.displayName = 'MessageInfo';

const mapStateToProps = (state) => {
    return {
        data: state
    }
}

const connect_MessageInfo = connect(
    mapStateToProps
)(MessageInfo);

export default withTranslation('common')(connect_MessageInfo);