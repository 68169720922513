export class Artifacts {
    constructor(text) {
        this.text = text;
        this.res_elements = [];
    }

    process = () => {
        var messages = this.bubbles(this.text);

        messages.forEach(msg => {
            var element = this.file(msg);
            if (element.length > 0) this.res_elements.push(element);
        })

        return this.res_elements;
    }

    bubbles = (input_text) => {
        return input_text.split('{bubble}');
    }

    file = (input_text) => {
        var regex_file = /({file [a-zA-Z0-9_\"= .\[\]^*\'ç?¿\-+,#·¡!$%&():/@]*})/,
            regex_file_values = /({([a-zA-Z0-9]*) type=\"([a-z]*)\" src=\"([a-zA-Z0-9/-@:%._\-\+~#=?&]+)\"*})/,
            parts = input_text.split(regex_file),
            array_elements = [];

        parts.forEach(part => {
            var match = regex_file_values.exec(part);
            if (match) {
                array_elements.push({'element': match[2], 'type': match[3], 'src': match[4]});
            } else if (!['', '\n'].includes(part.trim())) {
                array_elements.push(...this.postback(part));
            }
        })

        return array_elements
    }

    postback = (input_text) => {
        var regex_postback = /({postback\s+text\s*=\s*\"[^"]*\"\s*}(?:\s*{\s*button\s+(?:type\s*=\s*\"postback\"\s*payload\s*=\s*\"[^\n\t\"]+\"\s*|type\s*=\s*\"link\"\s*url\s*=\s*\"[\w]+[^"]*\"\s*)}[^{]+{\/button})*\s*{\/postback})/gm,
            regex_postback_match = /{\s*button\s+(?:type\s*=\s*\"(postback)\"\s*(payload)\s*=\s*\"([^\n\t\"]+)\"\s*|type\s*=\s*\"(link)\"\s*(url)\s*=\s*\"([\w]+[^"]*)\"\s*)}\s*([^{\n]+)\s*{\/button}/g,
            regex_postback_matchAll = /{\s*button\s+(?:type\s*=\s*\"(postback)\"\s*(payload)\s*=\s*\"([^\n\t\"]+)\"\s*|type\s*=\s*\"(link)\"\s*(url)\s*=\s*\"([\w]+[^"]*)\"\s*)}\s*([^{\n]+)\s*{\/button}/g,
            regex_postback_text = /{postback\s+text\s*=\s*\"([^"]*)\"\s*}/gm,
            parts = input_text.split(regex_postback),
            array_elements = [];

        parts.forEach(part => {
            var match = regex_postback_match.exec(part);
            if (match) {
                const postback_text = regex_postback_text.exec(part);
                const postback_buttons = [...part.matchAll(regex_postback_matchAll)];

                var postback_element = {}, postback_btn_array = [];
                postback_element.text = postback_text[1];
                postback_element.element = "postback";

                postback_buttons.forEach((button => {
                    postback_btn_array.push({
                        type: button[1] ?? button[4],
                        payload: button[3],
                        url: button[6],
                        text: button[7]
                    })
                }))

                postback_element.buttons = postback_btn_array;
                array_elements.push(postback_element);
            } else if (!['', '\n'].includes(part.trim())) {
                array_elements.push(...this.reply(part));
            }
        })

        return array_elements;
    }

    reply = (input_text) => {
        var regex_reply = /({reply(?:|\s*type\s*=\s*\"[^\"]*\")\s+text\s*=\s*\"[^\"]*\"\s*}(?:\s*{\s*button\s+payload\s*=\s*\"[^\n\t\"]+\"\s*icon_url\s*=\s*\"[^\"\s]*\"\s*}[^{]*{\/button})*\s*{\/reply})/gm,
            regex_reply_buttons = /{\s*button\s+payload\s*=\s*\"([^\n\t\"]+)\"\s*icon_url\s*=\s*\"([^\"\s]*)\"\s*}\s*([^{\n]*)\s*{\/button}/gm,
            regex_reply_values = /{reply(?:|\s*type\s*=\s*\"([^\"]*)\")\s+text\s*=\s*\"([^\"]*)\"\s*}/gm,
            parts = input_text.split(regex_reply),
            array_elements = [];

        parts.forEach(part => {
            var match = regex_reply.exec(part);
            if (match) {
                var reply_text = [...part.matchAll(regex_reply_values)],
                    reply_buttons = [...part.matchAll(regex_reply_buttons)],
                    reply_btn_array = [],
                    reply_element = {
                        type: reply_text[0][1] ?? "default",
                        text: reply_text[0][2],
                        element: "reply"
                    }

                reply_buttons.forEach((button => {
                    reply_btn_array.push({
                        payload: button[1],
                        url: button[2],
                        text: button[3]
                    })
                }))

                reply_element.buttons = reply_btn_array;

                if (["in_line_auto", "icons"].includes(reply_element.type)) {
                    if (this.res_elements.length > 0 || array_elements.length > 0) {
                        this.res_elements.push(array_elements);
                    }
                    this.res_elements.push([reply_element]);
                    array_elements = [];
                } else {
                    array_elements.push(reply_element);
                }
            } else if (!['', '\n'].includes(part.trim())) {
                array_elements.push(...this.reply_buttons(part));
            }
        })

        return array_elements;
    }

    reply_buttons = (input_text) => {
        var regex_reply_button = /({reply_button\s+body=[^}]*}(?:(?:\s*{reply[\w\W][^}]*})*)\s*{\/reply_button})/gm,
            regex_reply_button_values = /([a-z_]+)=\"([^\"}]*)\"/gm,
            parts = input_text.split(regex_reply_button),
            array_elements = [];

        parts.forEach(part => {
            var match = regex_reply_button.exec(part);
            if (match) {
                const reply_array = [...part.matchAll(regex_reply_button_values)];

                var reply_buttons_element = {
                    element: "reply_button",
                    values: []
                }

                reply_array.forEach(reply_ele => {
                    if (reply_ele[1] === "value") {
                        reply_buttons_element.values.push(reply_ele[2]);
                    } else {
                        reply_buttons_element[reply_ele[1]] = reply_ele[2];
                    }
                });

                array_elements.push(reply_buttons_element);
            } else if (!['', '\n'].includes(part.trim())) {
                array_elements.push(...this.list_messages(part));
            }
        })

        return array_elements;
    }

    list_messages = (input_text) => {
        var regex_list_messages = /({list_message\s+body=[^}]*}(?:(?:\s+{section [\w\W][^}]*}[\w\W\s][^/]*[^{/]+{\/section}*)*)\s*{\/list_message})/gm,
            regex_list_messages_header = /{list_message\s+body=[^}]*}/gm,
            regex_list_messages_values = /([a-z_]+)=\"([^\"}]*)\"/gm,
            regex_list_messages_sections = /({section [\w\W][^}]*}[\w\W\s][^/]*[^{/]+{\/section})/gm,
            regex_list_messages_sections_header = /({section [\w\W][^}]*})/gm,
            regex_list_messages_sections_header_values = /([a-z_]+)=\"([^\"}]*)\"/gm,
            regex_list_messages_sections_row = /({row [a-z_]+=[\w\W][^}]*})/gm,
            regex_list_messages_sections_row_values = /([a-z_]+)=\"([^\"}]*)\"/gm,
            parts = input_text.split(regex_list_messages),
            array_elements = [];

        parts.forEach(part => {
            var match = regex_list_messages.exec(part);
            if (match) {
                var element_match = match[0];

                // List Message Header
                var match_list_message_header_element = [...element_match.matchAll(regex_list_messages_header)][0],
                    match_list_message_header_values = [...match_list_message_header_element[0].matchAll(regex_list_messages_values)];

                // List Message Header Values
                var list_message_header_values = {};
                match_list_message_header_values.forEach((header_values => {
                    list_message_header_values[header_values[1]] = header_values[2];
                }))

                // Sections
                var match_list_message_sections = [...element_match.matchAll(regex_list_messages_sections)],
                    list_message_sections_values = [];
                match_list_message_sections.forEach(((section_element, i) => {
                    // Sections Header
                    var match_list_message_sections_element = section_element[0].match(regex_list_messages_sections_header)[0],
                        match_list_message_sections_values = [...match_list_message_sections_element.matchAll(regex_list_messages_sections_header_values)];

                    // Sections Header Values
                    list_message_sections_values.push({
                        [match_list_message_sections_values[0][1]]: match_list_message_sections_values[0][2],
                        rows: []
                    })

                    // Sections Rows
                    var match_list_message_row_element = [...section_element[0].matchAll(regex_list_messages_sections_row)];
                    match_list_message_row_element.forEach((row_element => {
                        // Rows Values
                        var match_list_message_row_values = [...row_element[0].matchAll(regex_list_messages_sections_row_values)];
                        list_message_sections_values[i].rows.push({
                            [match_list_message_row_values[0][1]]: match_list_message_row_values[0][2],
                            [match_list_message_row_values[1][1]]: match_list_message_row_values[1][2]
                        });
                    }))
                }))

                array_elements.push({
                    ...list_message_header_values,
                    element: 'list_message',
                    sections: list_message_sections_values
                })
            } else if (!['', '\n'].includes(part.trim())) {
                array_elements.push(...this.carousel(part));
            }
        })

        return array_elements;
    }

    carousel = (input_text) => {
        var regex_carousel = /({carousel(?:\s+type=[^}]*)?}(?:(?:\s*{item [\w\W\s][^/]*[^}]+}[\w\W\s][^/]*[^{/]+{\/item}*)*)\s*{\/carousel}*)/gm,
            regex_carousel_items = /({item [\w\W\s][^/]*[^}]+}[\w\W\s][^/]*[^{/]+{\/item}*)/gm,
            regex_carousel_values = /{carousel(?:|\s+type\s*=\s*\"([^\"]*)\")\s*}/gm,
            regex_carousel_props = /(({([a-zA-Z0-9]*) type=\"([a-z]*)\" src=\"([\w\W\s/-@:%._\-\+~#=?&]+)\" link=\"([a-zA-Z0-9/-@:%._\-\+~#=?&]+)\" value=\"([\w\W\s]*[^{/]*[^{}]*)\"})([\w\W\s][^{/]*[^{/]+)({\/[\w\W\s]*})*)/gm,
            parts = input_text.split(regex_carousel),
            array_elements = [];

        parts.forEach(part => {
            var match = regex_carousel.exec(part);
            if (match) {
                const carousel_text = [...part.matchAll(regex_carousel_values)];
                var parts_items = match.input.split(regex_carousel_items),
                    type = carousel_text[0][1] ?? "default";

                var carouselElements = [];
                parts_items.forEach(item => {
                    var params = item.split(regex_carousel_props);
                    if (params && params.length > 3) {
                        carouselElements.push({
                            element: params[4],
                            src: params[5],
                            link: params[6],
                            value: params[7],
                            title: params[8]
                        });
                    }
                });
                if (carouselElements.length > 0) {
                    if (["mobile"].includes(type)) {
                        if (this.res_elements.length > 0 || array_elements.length > 0) {
                            this.res_elements.push(array_elements);
                        }
                        this.res_elements.push([{element: 'carousel', type: type, elements_carousel: carouselElements}]);
                        array_elements = [];
                    } else {
                        array_elements.push({element: 'carousel', type: type, elements_carousel: carouselElements});
                    }
                }
            } else if (!['', '\n'].includes(part.trim())) {
                array_elements.push(...this.img(part));
            }
        })

        return array_elements;
    }

    img = (input_text) => {
        var regex_img = /({img [a-zA-Z0-9_\"= .\[\]^*\'ç?¿\-+,#·¡!$%&():/@]*})/gm,
            regex_img_values = /({([a-zA-Z0-9]*) src=\"([A-Za-zÀ-ÖØ-öø-ÿ0-9 \.\[\]\^\*\'\ç\?\¿\-+\:\_\,\/\#\n·¡!$%&/()={}@]*)\"*})/gm,
            parts = input_text.split(regex_img),
            array_elements = [];

        parts.forEach(part => {
            var match = regex_img_values.exec(part);

            if (match) {
                array_elements.push({
                    element: match[2],
                    src: match[3]
                });
            } else if (!['', '\n'].includes(part.trim())) {
                array_elements.push(...this.button(part));
            }
        })

        return array_elements;
    }

    button = (input_text) => {
        var regex_button = /({button [a-zA-Z0-9_À-ÿ\"= .\[\]^*\'ç?¿\-+,#·¡!$%&()@\w\W\]*}[\w\W\s][^/]*[^{/]*{\/button}*)/gm,
            regex_button_values = /(({(button) value=\"([\w\W\s][^{/]*)\"})([\w\W\s][^{/]*[^{/]*)({\/button})*)/gm,
            parts = input_text.split(regex_button),
            array_elements = [];

        parts.forEach(part => {
            var match = [...part.matchAll(regex_button_values)];
            if (match && match.length > 0) {
                array_elements.push({
                    element: match[0][3],
                    value: match[0][4],
                    text: match[0][5]
                });
            } else if (!['', '\n'].includes(part.trim())) {
                array_elements.push(...this.link(part));
            }
        })

        return array_elements;
    }

    link = (input_text) => {
        var regex_link = /({link [a-zA-Z0-9À-ÿ_\"= .\[\]^*\'ç?¿\-+,;#·¡!$%&():/@]*}[\w\W\s][^/]*[^{/]+{\/link}*)/gm,
            regex_link_values = /(({([a-zA-Z0-9]*) type=\"([a-z]*)\" href=\"([a-zA-Z0-9À-ÿ/-@:%._\-\+~!#=?&]+)\"*})([\w\W\s][^{/]*[^{}]+)({\/[\w\W\s]*})*)/gm,
            parts = input_text.split(regex_link),
            array_elements = [];

        parts.forEach(part => {
            var match = regex_link_values.exec(part);
            if (match) {
                array_elements.push({
                    element: match[3],
                    type: match[4],
                    href: match[5],
                    text: match[6]
                });
            } else if (!['', '\n'].includes(part.trim())) {
                array_elements.push(...this.txt(part));
            }
        })

        return array_elements;
    }

    txt = (input_text) => {
        var line_break = input_text.match(/\n/g) || [];
        var array_elements = [];
        if ((!(input_text.length === 2 && line_break.length === 2))) {
            if (line_break.length > 0 && input_text.startsWith("\n")) {
                if (line_break.length > 1) {
                    var remove_first = input_text.replace("\n", '');
                    var set_br = remove_first.replace(/\n/g, '<br>');
                    array_elements.push({element: 'text', text: set_br});
                } else {
                    array_elements.push({element: 'text', text: input_text});
                }
            } else {
                array_elements.push({element: 'text', text: input_text.replace(/\n/g, '<br>')});
            }
        }

        return array_elements;
    }
}