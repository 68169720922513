import React, {Component} from 'react';
import {connect} from 'react-redux'
import './styles.scss';
import {Card, CardContent} from "@material-ui/core";

class ReplyDetached extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uuid: this.getUuid()
        }
    }

    getUuid = () => {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }

    handleResponse = (e, value) => {
        const {incomingResponses} = this.props;

        this.props.dispatch({type: 'synthetic', detail: {type: 'visitor_send_msg', msg: value}});
        e.target.disabled = true;
        incomingResponses(value);
    }

    handleHeightImage = (i) => {
        const {uuid} = this.state;
        var element = document.getElementsByClassName(`button-icon_${uuid}_${i}`)[0];
        if (element) {
            element.height = element.width;
        }
    }

    render() {
        const {msg} = this.props;
        const {uuid} = this.state;
        const type = msg.type;
        return (
            <React.Fragment>
                <Card elevation={0} className={`chat-msg ${this.props.data.config.customization.messages_rubberband ? 'rubberBand' : ''} elements`}>
                    <CardContent>
                        <p dangerouslySetInnerHTML={{__html: msg.text}} />
                    </CardContent>
                </Card>
                <div className={"artifact_reply__detached"}>
                    <div className={`artifact_reply__buttons ${type}`}>
                        {
                            msg.buttons?.map((button, i) => {
                                return (
                                    type === "icons" ?
                                        button.url &&
                                        <img className={`button-icon button-icon_${uuid}_${i}`} key={i} src={button.url} alt={""} onLoad={() => this.handleHeightImage(i)} onClick={(e) => this.handleResponse(e, button.text)}/>
                                        :
                                        <button className={"btn-primary button-reply"} disabled={msg.disabled}
                                                onClick={(e) => this.handleResponse(e, button.text)}
                                                key={i}>{button.url && <img src={button.url} alt={""}/>}{button.text}</button>
                                )
                            })
                        }
                    </div>
                </div>
            </React.Fragment>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        data: state
    }
}

const connect_elements = connect(
    mapStateToProps
)(ReplyDetached);

export default connect_elements;
