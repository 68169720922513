import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {connect} from 'react-redux';
// Styles
import '../styles/styles.scss';
// Moment
import moment from 'moment';

class Article extends Component {
    render() {
        const {article} = this.props;
        return (
            <div className={"zendesk_guide_article"}>
                <div className={"element_date"}>{moment(article.updated_at).format('LLL')}</div>
                <div className={"element_title"}>{article.title}</div>
                <div className={"element_body"} dangerouslySetInnerHTML={{__html: article?.body}}/>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        data: state
    }
}

const connect_component = connect(
    mapStateToProps
)(Article);

export default withTranslation('common')(connect_component);