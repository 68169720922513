import React, {Component} from 'react';
import {connect} from 'react-redux'
import './styles.scss';

class ReplyDefault extends Component {
    constructor(props) {
        super(props);
    }

    handleResponse = (e, value) => {
        const {incomingResponses} = this.props;

        this.props.dispatch({type: 'synthetic', detail: {type: 'visitor_send_msg', msg: value}});
        e.target.disabled = true;
        incomingResponses(value);
    }

    render() {
        const {msg} = this.props;
        return (
            <div className={"artifact_reply"}>
                <div className={"artifact_reply__header"}>
                    <p dangerouslySetInnerHTML={{__html: msg.text}} />
                </div>
                <div className={"artifact_reply__buttons"}>
                    {
                        msg.buttons?.map((button, i) => {
                            return (
                                <button className={"btn-primary button-reply"} disabled={msg.disabled}
                                        onClick={(e) => this.handleResponse(e, button.text)}
                                        key={i}>{button.url && <img src={button.url} alt={""}/>}{button.text}</button>
                            )
                        })
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        data: state
    }
}

const connect_elements = connect(
    mapStateToProps
)(ReplyDefault);

export default connect_elements;
