// React
import React, {Component} from 'react';
import {connect} from 'react-redux'
// Material ui
import {FormControl, Radio, RadioGroup, FormControlLabel, Drawer, IconButton} from "@material-ui/core";
// CSS
import './styles.scss';
// SVG
import {List, Close} from "@material-ui/icons"
import {sendSvg} from "assets";

class ListMessage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            selected_value: false
        }
    }

    handleResponse = () => {
        const {incomingResponses} = this.props;

        this.toggleDrawer(false);
        this.props.dispatch({type: 'synthetic', detail: {type: 'visitor_send_msg', msg: this.state.selected_value}});
        incomingResponses(this.state.selected_value);
    }

    toggleDrawer = (value) => {
        this.setState({open: value, selected_value: false});
    }

    updateSelectValue = (e) => {
        this.setState({selected_value: e.target.value})
    }

    handleRowValue = (item) => {
        if (!item.desc) {
            return item.title;
        } return `${item.title}\n${item.desc}`;
    }

    render() {
        const {msg} = this.props;
        const {open, selected_value} = this.state;
        return (
            <React.Fragment>
                <Drawer
                    anchor={'bottom'}
                    open={open}
                    onClose={() => this.toggleDrawer(false)}
                    className={"artifact_list_message__drawer"}
                >
                    <div className={"artifact_list_message__drawer_header"}>
                        <span>{msg.button_text}</span>
                        <div onClick={() => this.toggleDrawer(false)}>
                            <IconButton aria-label="close"> <Close/> </IconButton>
                        </div>
                    </div>
                    <div className={"artifact_list_message__drawer_body"}>
                        <FormControl>
                            <RadioGroup
                                value={selected_value}
                                aria-labelledby="list_message_groups"
                                name="list-message-group"
                            >
                                {msg.sections.map((section, i) => {
                                    return (
                                        <div key={i} className={"artifact_list_message__drawer_sections"}>
                                            <span>{section.title}</span>
                                            {section.rows.map((row, i) => {
                                                return (
                                                    <div key={`${section.title}-${row.title}-${i}`} className={"artifact_list_message__drawer_rows"}>
                                                        <div>
                                                            <span>{row.title}</span>
                                                            <span>{row.desc}</span>
                                                        </div>
                                                        <div>
                                                            <FormControlLabel
                                                                value={this.handleRowValue(row)}
                                                                control={<Radio checked={selected_value === this.handleRowValue(row)} onChange={(e) => this.updateSelectValue(e)}/>}/>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )
                                })}
                            </RadioGroup>
                        </FormControl>
                    </div>
                    <div className={`artifact_list_message__drawer_button ${selected_value ? 'show' : ''}`}>
                        <span dangerouslySetInnerHTML={{__html: selected_value}} />
                        <div>
                            <IconButton aria-label={"send"} onClick={() => this.handleResponse()}>{sendSvg(1)}</IconButton>
                        </div>
                    </div>
                </Drawer>
                <div className={"artifact_list_message"}>
                    <div className={"artifact_list_message__header"}>
                        <span>{msg.header}</span>
                        <span>{msg.body}</span>
                        <span>{msg.footer}</span>
                    </div>
                    <div className={"artifact_list_message__buttons"} onClick={() => this.toggleDrawer(true)}>
                        <div>
                            <IconButton aria-label="close"> <List/> </IconButton>
                            <span>{msg.button_text}</span>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        data: state
    }
}

const connect_elements = connect(
    mapStateToProps
)(ListMessage);

export default connect_elements;
