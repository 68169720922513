import React, { Component } from 'react';
import { connect } from 'react-redux'

class ActionButton extends Component {

    handleResponse = (e, value) => {
        const { incomingResponses } = this.props;

        this.props.dispatch({ type: 'synthetic', detail: { type: 'visitor_send_msg', msg: value } });
        e.target.disabled = true;
        incomingResponses(value);
    }

    render() {
        const { text, value, disabled} = this.props;
        return (
            <button disabled={disabled} onClick={(e) => this.handleResponse(e, value)} className="btn-primary elements_button button" value={value}>{text}</button>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        data: state
    }
}

const action_button = connect(
    mapStateToProps
)(ActionButton);

export default action_button;
