import {updateChats} from "action";
import {handleChannelState} from "infrastructure/handleChannels";
import {Artifacts} from "./artifacts";

const iOS = () => {
    return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

const isMac = () => {
    return navigator.platform.indexOf('Mac') > -1
}

export const restartConnection = (welcome_message, self) => {
    if (!self.state.reconnection) {
        self.setState({reconnection: true});
    }

    if (self.props.isOnline) {
        handleWebsocket(welcome_message, self);
    } else {
        setTimeout(() => {
            restartConnection(welcome_message, self);
        }, 5000)
    }
}

export const handleWebsocket = (welcome_message, self, user_id) => {
    const {config, sessionID, userID, projectID} = self.props.data;
    let ws = new WebSocket(process.env.REACT_APP_URL_WEBSOCKET);

    ws.onopen = () => {
        ws.send(JSON.stringify({
            type: "connect",
            channel: `${projectID}_${user_id ? user_id : userID}`,
            from: 'chat_web'
        }));

        heartbeat();

        if (self.state.reconnection) {
            self.historicMessages(welcome_message, config, sessionID, user_id ? user_id : userID, projectID, true);
        }
    }

    ws.onmessage = async (res) => {
        if (res && res.data) {
            const dataFromServer = JSON.parse(res.data),
                message = dataFromServer.message,
                msg = message?.text?.replace(/(?:\r\n|\r|\n)/g, '<br>'),
                desk = dataFromServer.user.desk;

            const {config, channel} = self.props.data;

            self.handleChannels(config.channels, desk);
            if (channel && !desk && channel !== 'ivr_powers' || channel && dataFromServer.event === "session_updated") {
                const req = new handleChannelState(self.props);
                await req.removeChannel();
            }

            switch (dataFromServer.event) {
                case 'message': {
                    if (message?.attachments.length > 0) {
                        self.props.dispatch({type: 'synthetic', detail: {type: 'agent_send_msg', msg}});
                        message.attachments.forEach(attachment => {
                            var type = attachment.type;
                            if (attachment.type === "file") {
                                type = attachment.filetype;
                            }
                            switch (type) {
                                case 'image':
                                case 'document':
                                case 'video':
                                case 'audio':
                                case 'application':
                                    self.props.dispatch({type: 'file', detail: {msg, attachment}});
                                    break;
                            }
                        });
                    } else {
                        var artifacts = new Artifacts(message.text),
                            elements = artifacts.process();

                        self.handleElements(elements, msg);
                    }
                    break;
                }
            }
        }
    }

    ws.onclose = () => {
        ws = null;
        restartConnection(welcome_message, self);
    }

    ws.onerror = () => {
        ws.close();
    };

    const heartbeat = () => {
        if (!self.props.isOnline) {
            waitToOnline();
        } else if (ws && ws.readyState === 1) {
            ws.send(JSON.stringify({
                type: "heartbeat",
                channel: `${projectID}_${user_id ? user_id : userID}`,
                from: 'chat_web'
            }));

            setTimeout(heartbeat, 5000);
        }
    }

    const waitToOnline = () => {
        if (!self.props.isOnline) {
            if (!self.state.reconnection) {
                self.setState({reconnection: true});
            }
            setTimeout(waitToOnline, 5000);
        } else if (iOS() || isMac()) {
            restartConnection(welcome_message, self);
        } else {
            ws?.close();
        }
    }
}