import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {connect} from 'react-redux';
// Styles
import '../styles/styles.scss';
import {CircularProgress, Divider} from "@material-ui/core";
// Moment
import moment from 'moment';
// Material UI
import Pagination from '@material-ui/lab/Pagination';

class Articles extends Component {
    handlePagination = (e, page_selected) => {
        const {getArticles} = this.props;
        var {page, next_page, previous_page} = this.props.articles;

        var get_page, operator;
        if (page_selected > page) {
            get_page = next_page;
            operator = "+";
        } else {
            get_page = previous_page;
            operator = "-";
        }

        var query = get_page.split("search.json?")[1];
        query = query.replaceAll(`page=${eval(page + operator + 1)}`, `page=${page_selected}`);

        getArticles(false, query.replaceAll(`page=${eval(page + operator + 1)}`, `page=${page_selected}`));
    }

    render() {
        const {articles, selectArticle, loading, t} = this.props;
        return (
            <div className={"zendesk_guide_articles"}>
                <div className={"zendesk_guide_articles_elements"}>
                    {
                        articles?.count > 0 &&
                        <div className={"zendesk_guide_articles_count"}>
                            <span>{articles?.count === 1 ? t('zendesk_guide.result', {articles_count: articles?.count}) : t('zendesk_guide.results', {articles_count: articles?.count})}</span>
                        </div>
                    }
                    {
                        loading ?
                            <span className={"zendesk_guide_container_loading"}><CircularProgress size={50}/></span>
                            :
                            articles?.results?.length === 0 ?
                                <div className={"zendesk_guide_container_feedback"}>
                                    <img className="results_feedback"
                                         src={process.env.PUBLIC_URL + '/assets/results_feedback.png'}
                                         alt={"results_feedback"}/>
                                    <span
                                        className={"zendesk_guide_container_info"}>{t('zendesk_guide.feedback')}</span>
                                </div>
                                :
                                articles?.results?.map((article, i) => {
                                    return (
                                        <React.Fragment key={i}>
                                            <div className={"zendesk_guide_articles_element"}
                                                 onClick={() => selectArticle(article)}>
                                                <span className={"element_title"}>{article.title}</span>
                                                <span className={"element_body"}
                                                      dangerouslySetInnerHTML={{__html: article.body?.replace(/<[^>]+>/g, '')}}/>
                                                <span
                                                    className={"element_date"}>{moment(article.updated_at).format('LLL')}</span>
                                            </div>
                                            {
                                                (i + 1) !== articles?.results.length &&
                                                <Divider/>
                                            }
                                        </React.Fragment>
                                    )
                                })
                    }

                </div>
                {
                    articles?.page_count > 1 &&
                    <Pagination page={articles.page} count={articles.page_count} siblingCount={0} shape="rounded"
                                onChange={this.handlePagination}/>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        data: state
    }
}

const connect_component = connect(
    mapStateToProps
)(Articles);

export default withTranslation('common')(connect_component);