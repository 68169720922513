import React, {Component} from 'react';
import {TextField} from '@material-ui/core';
import {sendSvg} from 'assets';

class Input extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: ''
        }
    }

    onSubmit = (e) => {
        e && e.preventDefault();
        this.props.getArticles(this.state.value);
    }

    render() {
        const {getArticles, svg, input_text} = this.props;
        const {value} = this.state;
        return (
            <form autoComplete="off" className="form-container" onSubmit={(e) => this.onSubmit(e)}>
                <TextField id="b-chat-input" onChange={(e) => this.setState({value: e.target.value})} value={value}
                           placeholder={input_text}/>
                <div className={`send-button svg-${svg}`} onClick={() => getArticles(value)}> {sendSvg(svg)} </div>
            </form>

        );
    }
}

export default Input;
