import React, {Component} from 'react';
import {connect} from 'react-redux'
import {CardMedia, Grid, Typography} from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';
import $ from "jquery";

function Item(props) {
    return (
        <Grid className="carousel-item">
            <CardMedia
                className="carousel-media"
                image={props.item.src}
                title={props.item.title}
                onClick={() => window.open(props.item.link)}
            >
            </CardMedia>
            <Typography onClick={(e) => props.handleResponse(props.item.value)} className="carousel-media-caption">
                {props.item.title}
            </Typography>
        </Grid>
    )
}

class CarouselDefault extends Component {
    constructor(props) {
        super(props);
    }

    handleResponse = (value) => {
        if (value) {
            const {incomingResponses} = this.props;
            this.props.dispatch({type: 'synthetic', detail: {type: 'visitor_send_msg', msg: value}});

            incomingResponses(value);
        }
    }

    render() {
        const {items} = this.props;
        return (
            items?.length > 0 &&
            <Carousel
                className="carousel"
                autoPlay={false}
                animation={"slide"}
                indicators={true}
                timeout={200}
                navButtonsAlwaysVisible={true}
                navButtonsWrapperProps={{
                    style: {
                        height: '85%'
                    }
                }}
            >
                {
                    items.map((item, i) => {
                        return <Item item={item} handleResponse={this.handleResponse}
                                     incomingResponses={this.props.incomingResponses} key={i}/>
                    })
                }
            </Carousel>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        data: state
    }
}

const action_carousel = connect(
    mapStateToProps
)(CarouselDefault);

export default action_carousel;
