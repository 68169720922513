'use strict';
import React, {Component} from 'react';
export class ChatApplication extends Component {
    render() {
    const {attachment} = this.props;
        return (
            <a href={attachment.url} target="_blank">
                <div className="chat-msg">
                    descargar
                </div>
            </a>
        );
    }
}