import { ZendeskChat } from './zendesk_chat';

export class integrationCall {
    constructor(props, channel, action, params) {
        this.props = props;
        this.channel = channel;
        this.action = action;
        this.params = params;
    }

    async zendesk_chat() {
        const req = new ZendeskChat(this.props);
        await req[this.action](this.params);
    }

    async call() {
        switch (this.channel) {
            case 'zendesk_chat':
                return await this.zendesk_chat();
        }
    }
}