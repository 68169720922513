import React, {Component} from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Button, CircularProgress, TextField} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
// Moment
import moment from 'moment';
import {sendSvg, svgClip} from 'assets';
import {updateWarning} from "action";

class MessageInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            too_much_messages: false
        }
    }

    componentDidMount = () => {
        setInterval(() => {
            this.controlLimit();
        }, 5000)
    }

    componentDidUpdate = (nextProps) => {
        if (nextProps.data.chats.length !== this.props.data.chats.length) {
            this.controlLimit();
        }
    }

    handleChange = (event) => {
        this.setState({value: event.target.value});
    }

    onSubmit = (e) => {
        this.setState({value: ''});
        this.props.onSubmit(e);
    }

    handlePlaceholder = (sendText) => {
        const {projectID, t} = this.props;
        if (projectID === "e912cb15787a4bdc8e18fc0559ea91dc") {
            return t('tradeInn_sendText');
        } else if (projectID === "e49682152d784785a16f5a06f43b61ac") {
            return t('drivania_sendText');
        } else if (sendText) {
            return sendText;
        } else {
            return 'Inserta mensaje aquí...';
        }
    }

    controlLimit = async () => {
        const {channel, chats, warning} = this.props.data;

        var current_timestamp = + new Date(),
            messages = JSON.parse(JSON.stringify(chats)),
            too_much_messages = false,
            count = 0;

        if (channel === "zendesk_full") {
            messages.reverse().forEach(c => {
                if (c.display_name === "visitor" && c.desk_name === "zendesk_full") {
                    var difference = moment(current_timestamp).diff(c.timestamp, 'minutes');
                    if (difference <= 10) { count++; }
                    if (count >= 30) { too_much_messages = true; }
                }
            });
        }

        if (too_much_messages && warning.type !== "too_much_messages") {
            this.props.dispatch(updateWarning({type: "too_much_messages"}));
        } else if (!too_much_messages && warning.type === "too_much_messages") {
            this.props.dispatch(updateWarning(false));
        }
    }

    render() {
        const {
            onFileUpload,
            sendText,
            channel,
            sessionID,
            svg,
            attachmentActive,
            sendingAttachment,
            isOnline,
            reconnection,
            t
        } = this.props;
        const {value} = this.state;
        const {warning} = this.props.data;
        return (
            warning?.type === "too_much_messages" ?
                <Alert className="form-container too_much_messages" severity="error">{t("too_much_messages")}</Alert> :
                warning?.type === "duplicate_message" ?
                    <Alert className="form-container" onClose={() => {
                        this.props.dispatch(updateWarning(false))
                    }} severity="error">{t(warning.type)}</Alert>
                    : isOnline && !reconnection ?
                    <form autoComplete="off" className="form-container" onSubmit={this.onSubmit}>
                        {
                            !channel && value.length >= 200 && (
                                <span
                                    className={`counter-lng ${value.length >= 255 ? 'error' : ''}`}>{value.length}/255</span>
                            )
                        }
                        <TextField disabled={!sessionID} inputProps={{maxLength: !channel ? 255 : 6000}}
                                   id="b-chat-input" onChange={this.handleChange} value={value}
                                   placeholder={this.handlePlaceholder(sendText)}/>
                        {
                            attachmentActive &&
                            <div className={"form-upload-file"}>
                                {sendingAttachment ?
                                    <Button variant="contained" component="span">
                                        <CircularProgress size={20}/>
                                    </Button> :
                                    <Button variant="contained" component="label">
                                        {svgClip()}
                                        <input type="file" onChange={(e) => onFileUpload(e)} hidden/>
                                    </Button>
                                }
                            </div>
                        }
                        <div className={`send-button svg-${svg}`} onClick={this.onSubmit}> {sendSvg(svg)} </div>
                    </form> :
                    <form autoComplete="off" className="form-container">
                        <div className={"input_offline"}>
                            <div>{t('offline_1')}</div>
                            <div>
                                <span>{t('offline_2')}</span>
                                <div className={"lds-ellipsis"}>
                                    <div/>
                                    <div/>
                                    <div/>
                                    <div/>
                                </div>
                            </div>
                        </div>
                    </form>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        data: state
    }
}

const connect_input = connect(
    mapStateToProps
)(MessageInput);

export default withTranslation('common')(connect_input);
