import React, {Component} from 'react';
import {connect} from 'react-redux'
import {Card, CardContent, Typography} from '@material-ui/core';
import Reply from 'components/Artifacts/Reply';
import File from 'components/Artifacts/File';
import ReplyButton from 'components/Artifacts/ReplyButton';
import Postback from 'components/Artifacts/Postback';
import ListMessage from 'components/Artifacts/ListMessage';
import ActionButton from './ActionButton';
import Carousel from 'components/Artifacts/Carousel';
import {OpenInNew} from '@material-ui/icons';
import Avatar from "./Avatar";

class ChatElements extends Component {
    constructor(props) {
        super(props);
    }

    handleCardClass = (messages) => {
        var card_class = "default", artifact_class = "";
        messages?.forEach(message => {
            if (message.element === "reply" && ["in_line_auto", "icons"].includes(message.type) || message.element === "carousel" && ["mobile"].includes(message.type)) {
                card_class = "detached";
            }

            if (message.element !== "text") {
                artifact_class = "elements";
            }
        })

        return `${card_class} ${artifact_class}`;
    }

    isArabicLocale = (msg) => {
        var regExp = /[\u0600-\u06FF]/;

        var res = "";
        msg?.forEach(message => {
            if (message.element === "text" && regExp.test(message.text)) {
                res = "locale_arabic";
            }
        })

        return res;
    }

    renderMessagePart = (msg) => {
        return (
            <Card elevation={0}
                  className={`chat-msg ${this.props.data.config.customization.messages_rubberband ? 'rubberBand' : ''} ${this.handleCardClass(msg.msg)} ${this.isArabicLocale(msg.msg)}`}>
                <CardContent>
                    {
                        msg.msg.map((message, i) => {
                            return this.renderByType(message, msg.disabled, i);
                        })
                    }
                </CardContent>
            </Card>
        );
    }

    renderByType = (msg, disabled, i) => {
        msg.disabled = disabled;
        switch (msg.element) {
            case 'reply_button':
                return (
                    <ReplyButton key={`artifact_reply_button-${i}`} msg={msg} incomingResponses={this.props.incomingResponses}/>
                )
            case 'reply':
                return (
                    <Reply key={`artifact_reply-${i}`} msg={msg} incomingResponses={this.props.incomingResponses}/>
                )
            case 'postback':
                return (
                    <Postback key={`artifact_postback-${i}`} msg={msg} incomingResponses={this.props.incomingResponses}/>
                )
            case 'list_message':
                return (
                    <ListMessage key={`artifact_list_message-${i}`} msg={msg} incomingResponses={this.props.incomingResponses}/>
                )
            case 'text':
                return (
                    <Typography key={`artifact_text-${i}`} color="textSecondary"
                                dangerouslySetInnerHTML={{__html: msg.text}}/>
                )
            case 'button':
                return (
                    <ActionButton key={`artifact_action_button-${i}`} incomingResponses={this.props.incomingResponses}
                                  text={msg.text}
                                  disabled={msg.disabled}
                                  value={msg.value}/>
                )
            case 'carousel':
                return (
                    <Carousel
                        key={`artifact_carousel-${i}`}
                        msg={msg}
                        items={msg.elements_carousel}
                        incomingResponses={this.props.incomingResponses}
                    />

                )
            case 'link':
                switch (msg.type) {
                    case 'button':
                        return (
                            <button key={`artifact_button-${i}`} onClick={(e) => window.open(msg.href)}
                                    className="btn-primary elements_button button-link">{msg.text} <span
                                className={"button-new"}><OpenInNew/></span></button>
                        )
                    case 'text':
                    default:
                        return (
                            <a key={`artifact_link-${i}`} href={msg.href} target="_blank"><p>{msg.text}</p></a>
                        )
                }
            case 'whatsapp':
                return (
                    <button key={`artifact_whatsapp-${i}`}
                            onClick={(e) => window.open(`https://api.whatsapp.com/send?phone=${msg.phone}&text=${msg.text}`)}
                            className="btn-primary elements_button button-whatsapp">WhatsApp</button>
                );
            case 'img':
                return (
                    <img key={`artifact_img-${i}`} src={msg.src}/>
                );
            case 'file':
                return (
                    <File key={`artifact_file-${i}`} msg={msg} />
                )
            default:
                return false
        }
    }

    render() {
        const {config} = this.props.data;
        return (
            this.props.message &&
            <div className={`chat-msg-container ${this.props.message.nick} ${this.props.addClass}`}>
                <Avatar config={config} nick={this.props.message.nick}/>
                {this.renderMessagePart(this.props.message)}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        data: state
    }
}

const connect_elements = connect(
    mapStateToProps
)(ChatElements);

export default connect_elements;
