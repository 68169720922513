// SDK Zendesk
import zChat from 'vendor/web-sdk';
import { CustomRequest } from '../infrastructure/customRequest';
import { handleChannelState } from '../infrastructure/handleChannels';

export class ZendeskChat {
    constructor(props) {
        this.props = props;
    }

    async init() {
        try {
            var status = zChat.getConnectionStatus();
            if ( status === 'closed' ) {
                zChat.init({ account_key: this.props.data.accountKEY });
                zChat.addTag('centribot_zendesk_chat', function(err){if (err) {console.log('error tag', err);}});
                this.events();
            }
        }
        catch(error) {
            zChat.init({ account_key: this.props.data.accountKEY });
            zChat.addTag('centribot_zendesk_chat', function(err){if (err) {console.log('error tag', err);}});
            this.events();
        }
    }

    events() {
        const events = [
            'account_status',
            'connection_update',
            'department_update',
            'visitor_update',
            'agent_update',
            'chat',
            'error'
        ];
        events.forEach((evt) => {
            zChat.on(evt, (data) => {
                switch ( data.type ) {
                    case 'chat.msg':
                        if ( !data.msg.split('||')[1] ) {
                            this.props.dispatch({
                                type: 'file_from_chat',
                                detail: data
                            });
                        }break;
                    case 'chat.memberleave':
                        if (data.nick !== 'visitor') {
                            this.endChat()
                        }
                        break;
                    case 'chat.file':
                        this.props.dispatch({
                            type: 'file_from_chat',
                            detail: data
                        });break;
                }
            });
        });
    }

    endChat() {
        this.putSession();
        zChat.endChat();
    }

    async sendMessage(msg) {
        zChat.sendChatMsg(msg, (err) => {
            if (err) {
              return;
            }
        });
    }

    async sendFile(file) {
        zChat.sendFile(file, (err) => {
            if (err) {
                return;
            }
        });
    }

    chatRating(rating, commentRating) {
        zChat.sendChatRating(rating, function(err) {});
        zChat.sendChatComment(commentRating, function(err) {});

        var data = {
            display_name: "Visitor",
            nick: "visitor",
            timestamp: + new Date(),
            type: "chat.rating.message"
        }
        this.props.dispatch({
            type: 'system_message',
            detail: data
        });

        this.endChat();
    }

    putSession = async () => {
        const { sessionID, projectID, chatID, modeCookie } = this.props.data;
        try {
          const body = { session_id: sessionID, desk: false }
          const request = new CustomRequest('PUT', `projects/${projectID}/chats/${chatID}/sessions/${sessionID}?test=${modeCookie}`, JSON.stringify(body));
          await request.action();

          const req = new handleChannelState(this.props);
          await req.removeChannel();

        } catch (err) {
        }
      }

}