import React, {Component} from 'react';
import {connect} from 'react-redux'
import {Card, CardContent, Typography} from '@material-ui/core';
import ChatMedia from 'components/ChatMedia';
import Avatar from 'components/Avatar';

class ChatMessage extends Component {

    renderMessagePart = (msg) => {
        switch (msg.type) {
            case 'chat.file':
                return (
                    <Card elevation={0} className={`chat-media ${this.props.data.config.customization.messages_rubberband ? 'rubberBand' : ''}`}>
                        <CardContent>
                            <ChatMedia message={msg}/>
                        </CardContent>
                    </Card>
                )
            default:
                return (
                    <Card elevation={0} className={`chat-msg ${this.props.data.config.customization.messages_rubberband ? 'rubberBand' : ''}`}>
                        <CardContent>
                            <Typography color="textSecondary"
                                        dangerouslySetInnerHTML={{__html: this.props.message.msg}}>
                            </Typography>
                        </CardContent>
                    </Card>
                );
        }
    }

    render() {
        const {config} = this.props.data;
        return (
            <div className={`chat-msg-container ${this.props.message.nick} ${this.props.addClass}`}>
                <Avatar config={config} nick={this.props.message.nick}/>
                {this.renderMessagePart(this.props.message)}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        data: state
    }
}

const connect_message = connect(
    mapStateToProps
)(ChatMessage);

export default connect_message;

