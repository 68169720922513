export const setCookieSession = (session, chatID, cookieName, expiredTime) => {
    var now = new Date();
    var time = now.getTime();
    if (cookieName === 'bc__s') {
        time += 3600 * 1000 * 24;
    } else if (cookieName === 'bc__u') {
        time += 3600 * 1000 * 24 * 365;
    } else if (cookieName === 'bc__m') {
        time += expiredTime ? (expiredTime * 3600 * 1000) : 0;
    } else {
        time += 3600 * 1000;
    }
    now.setTime(time);
    document.cookie = [`${cookieName}_${chatID}=${session}; expires=${now.toUTCString()}; SameSite=None; Secure;`].join('');
}

export const getCookieInfo = (param, chatID, cookieName) => {
    var nameEQ = `${cookieName}_${chatID}=`;
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) {
            var cookie;
            try {
                cookie = JSON.parse(decodeURIComponent(escape(atob(c.substring(nameEQ.length, c.length)))));
            } catch (e) {
                cookie = JSON.parse(c.substring(nameEQ.length, c.length));
            }
            return param ? cookie[param] : cookie;
        }
    }
    return null;
}

export const setChatsStorage = (session, chatID, expiredHours) => {
    const now = new Date(),
        expiry = now.getTime() + (expiredHours ? 8 * 3600 * 1000 : 0);

    try {
        localStorage[`bc__m_${chatID}`] = JSON.stringify({chats: session, expiry: expiry});
    } catch (e) {}
}

export const getChatsStorage = (chatID) => {
    try {
        const itemStr = localStorage.getItem(`bc__m_${chatID}`);

        if (!itemStr) {
            return [];
        }

        const item = JSON.parse(itemStr),
            now = new Date()

        if (now.getTime() > item.expiry) {
            localStorage.removeItem(`bc__m_${chatID}`)
            return [];
        }

        return JSON.parse(item.chats);
    } catch (e) {
        return [];
    }
}

export const deleteConfigCookie = (chatID) => {
    document.cookie = [`bc__cf_${chatID}=; expires=Thu, 01-Jan-1970 00:00:01 GMT; path=/;`].join('');
}

export const getParamsUrl = (type) => {
    const queryString = window.location.search,
        urlParams = new URLSearchParams(queryString),
        url = urlParams.get('key'),
        mode = urlParams.get('mode'),
        close = urlParams.get('close'),
        no_animation = urlParams.get('no_animation');
    var res;

    if (url) {
        switch (type) {
            case 'project_id':
                res = url.split('.')[0];
                break;
            case 'chat_id':
                res = url.split('.')[1];
                break;
            case 'mode':
                if (mode === 'static') {
                    res = true;
                }
                break;
            case 'close':
                res = close;
                break;
            case 'no_animation':
                res = no_animation;
                break;
            default:
                res = false;
                break;
        }
    }
    return res;
}

export const getChannels = () => {
    return [
        'zendesk_chat',
        'zendesk_full'
    ]
}

export const createSession = () => {
    var token = generateToken(14);
    var theDate = new Date();
    var oneYearLater = new Date(theDate.getTime() + 31536000000);
    var expiryDate = oneYearLater.toGMTString();
    document.cookie = [`__zlcmid=${token}; expires=${expiryDate}; SameSite=None; Secure;`].join('');
}

export const existSession = () => {
    if (!document.cookie.includes('__zlcmid=')) {
        createSession();
    }
}

export const removeSession = () => {
    document.cookie = ['__zlcmid=; expires=Thu, 01-Jan-1970 00:00:01 GMT; path=/;'].join('');
}

export const generateToken = (length) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const getCustomizationBody = () => {
    return {
        "chat_bg_color": "#FFFFFF",
        "welcome_incoming": "",
        "welcome_selector": "text",
        "open_text_color": "#565671",
        "open_bg_color": "#FFFFFF",
        "open_shape": "50px 50px 50px 50px",
        "open_on_load_timer": 0,
        "chat_timer": 0,
        "open_icon_show": true,
        "header_text_color": "#565671",
        "header_bg_color": "#FFFFFF",
        "header_icon_show": true,
        "messages_rubberband": false,
        "messages_content_bg_color": "#F7F7F7",
        "messages_user_text_color": "#565671",
        "messages_user_bg_color": "#FFFFFF",
        "messages_user_icon_show": false,
        "messages_agent_text_color": "#565671",
        "messages_agent_bg_color": "#D8D8DD",
        "messages_agent_icon_show": false,
        "artifacts_button_text_color": "#FFFFFF",
        "artifacts_button_bg_color": "#565671",
        "artifacts_button_hover_text_color": "#565671",
        "artifacts_button_hover_bg_color": "#88FAB8",
        "artifacts_button_disabled_text_color": "#FFFFFF",
        "artifacts_button_disabled_bg_color": "#808080",
        "artifacts_link_button_text_color": "#FFFFFF",
        "artifacts_link_button_bg_color": "#565671",
        "artifacts_link_button_hover_text_color": "#565671",
        "artifacts_link_button_hover_bg_color": "#88FAB8",
        "google_analytics": "",
        "input_text_color": "#565671",
        "input_bg_color": "#FFFFFF",
        "input_icon": 1,
        "input_icon_color": "#FFFFFF",
        "input_clip_color": "#FFFFFF",
        "input_clip_bg_color": "#565671",
        "input_icon_bg_color": "#565671",
        "input_show": true,
        "historic_messages": 8,
        "attachment_clip": true
    }
}

export const isConvertedJSON = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export const poweredVisibility = (plan) => {
    switch (plan) {
        case 0:
        case 1:
            return true;
        case 2:
        case 3:
        case 4:
            return false;
        default:
            return false;
    }
}